<template>
  <div>{{ $route.path }} - 404 not found</div>
</template>

<script>
export default {
  name: "404NotFound",
};
</script>

<style></style>
